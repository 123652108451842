import { template as template_f54a0c7aa3ee4849a482b684daab3c50 } from "@ember/template-compiler";
const FKLabel = template_f54a0c7aa3ee4849a482b684daab3c50(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
