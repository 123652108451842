import { template as template_cbbc0fda11e84b4cb8ec5c8302f9650a } from "@ember/template-compiler";
const FKText = template_cbbc0fda11e84b4cb8ec5c8302f9650a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
